@import "assets/css/settings";

:root {
  --iren-blue-color: #6B9BFF;
  --primary: #cc7c1b;
  --secondary: #EE7202;
  --tertiary: var(--iren-blue-color);
  --blue: #0bbbef;
}

::selection {
  background: #2663d2;
}


//LOADER
.page-loader-wrapper, .async-loader-wrapper{
  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--primary) !important;
    text-align: center;
    animation: spin 2s linear infinite;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--secondary) !important;
      animation: spin 3s linear infinite;
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--tertiary) !important;
      animation: spin 1.5s linear infinite;
    }
  }

  @keyframes spin {
    0%   {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

//MAIN PAGE
.main-container{
  margin-top: 0 !important;
  padding-top: 0!important;
  background-color: var(--white);
}

.container-xl.container-sm.main-container {
  padding-top: revert !important;
}

.jbtn {
  all: unset;
  --jbtn-bg-color: var(--secondary);
  --jbtn-bg-color-dark: var(--secondary);
  --jbtn-color: var(--color-primary-dark);
  --jbtn-hover-bg: var(--color-primary-light-lighter);
  --jbtn-padding: 0.5rem 3rem;
  --jbtn-border-color: var(--color-primary-dark);
  --jbtn-shadow: var(--gray-300-trasparent);
  --jbtn-font-weight: var(--fs-weight--bold);

  font-weight: var(--jbtn-font-weight);
  color: var(--jbtn-color);
  text-transform: uppercase;
  padding: var(--jbtn-padding);
  background: var(--jbtn-bg-color);
  border: 1px solid var(--jbtn-hover-bg) !important;
  border-radius: var(--btn-roundness);
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
  position: relative;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    --jbtn-bg-color: var(--gray-300-trasparent);
    --jbtn-bg-color-dark: var(--gray-300-trasparent);
    --jbtn-hover-bg: var(--gray-300-trasparent);
    --jbtn-border-color: var(--gray-300-trasparent);
    --jbtn-shadow: var(--gray-300-trasparent);
    --color-primary-light-lighter: var(--gray-300-trasparent);

    &.jbtn::before {
      box-shadow: 0 0 0 1px var(--jbtn-border-color), 0 0 var(--jbtn-shadow);
      transform: translate3d(0, 0, -1em);
    }
  }

  &--primary {
    --jbtn-bg-color: var(--color-primary);
    --jbtn-color: var(--white);
  }

  &--secondary {
    --jbtn-bg-color: var(--color-primary-dark);
    --jbtn-color: var(--white);
  }

  &--tertiary {
    --jbtn-padding: 0.4rem 1rem;
  }

  &--side-icon {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    border: 0;
    height: 2rem;
    text-decoration: none !important;
    align-items: center;
  }

  &-swal,
  &-swal:focus {
    --jbtn-bg-color: var(--color-primary);
    --jbtn-color: var(--gray-700);
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-primary-light-lighter);
    border-radius: inherit;
    box-shadow: 0 0 0 2px var(--color-primary-light-lighter), 0 0.200rem 0 0 var(--jbtn-shadow);
    transform: translate3d(0, 0.35em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }

  &:hover {
    background: var(--jbtn-bg-color);
    transform: translate(0, 0.10em);

    &::before {
      box-shadow: 0 0 0 1px var(--jbtn-shadow), 0 0.3em 0 0 var(--jbtn-shadow);
      transform: translate3d(0, 0.35em, -1rem)
    }
  }

  &:active {
    background: var(--jbtn-hover-bg);
    transform: translate(0em, 0.35em);

    &::before {
      box-shadow: 0 0 0 1px var(--jbtn-border-color), 0 0 var(--jbtn-shadow);
      transform: translate3d(0, 0, -1em);
    }
  }

  &.is-checked {
    background: var(--jbtn-hover-bg);
    transform: translate(0em, 0.35em);

    &.jbtn::before {
      box-shadow: 0 0 0 1px var(--jbtn-border-color), 0 0 var(--jbtn-shadow);
      transform: translate3d(0, 0, -1em);
    }
  }
}

.nav-link{
  color: var(--color-primary-dark) !important;
  &:hover{
    text-decoration: underline;
  }
}

#captchaWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.js-captcha-error {
  background: var(--primary);
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  line-height: 1.6;
  border-radius: 3px;
  min-width: 200px;
  margin-top: 1rem;
}

html {
  &::-webkit-scrollbar {
    display: none;
    background: transparent;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
}

body {
  margin: 0;
  background: var(--secondary);
  color: #171717;
}

//body > .container {
//  padding: 2rem 1rem 3rem;
//  margin-top: 10rem;
//
//  background-color: #ffffff;
//
//  transition: all .25s ease-in-out;
//
//  &.home-page
//  {
//
//  }
//
//  @media (min-width: 992px) {
//
//  }
//}

.main-navbar{
  //position: fixed;
  //top: 0;
  //left: 0;

}
.main-menu-nav{
  //background: linear-gradient(90deg, #EE7202 0%, #E30813 100%);
  background-color: var(--color-primary-light-lighter);
}

.user-image{
  content:url("./../../images/icons/condominio.png");
  width: 4rem;
}

.custom-name-label, .custom-surname-label, .custom-fiscal-code-label {
  visibility: hidden;
  position: relative;
}

.table-color-primary {
  background: var(--color-primary-light-lighter);
  color: var(--white);
}

.custom-name-label:after{
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "Nome amministratore pro tempore";
}

.custom-surname-label:after{
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "Cognome amministratore protempore";
}

.custom-fiscal-code-label:after{
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "Codice Fiscale Condominio";
}


.navbar {
  background: var(--white);
  padding: 1rem;
  transition: all .33s ease-in-out;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.stuck {
    padding: .75rem;

    & .navbar-brand {
      transition: all .33s ease-in-out;
      transform: scale(.9);
    }
  }

  & .container {
    justify-content: space-between;
  }

  & .navbar-brand {
    max-width: 220px;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    transition: all .33s ease-in-out;
    transform-origin: center;
  }

  & .img-logo {
    position: relative;
    z-index: 2;
    width: 6rem;
    height: auto;
  }
}

.container-xl {
  padding: 0 2rem !important;
}

.jumbotron.jumbotron {
  margin-top: 2rem;
  margin-bottom: 0;
  padding: 2rem;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  z-index: 2;
}

.form-btn {
  //background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%) !important;
  background-color: var(--secondary);
  border: 0;
  color: #fff;
  padding: 1.25rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: .5px;
  font-size: 1.25rem;
  border-radius: 100px;
}

.form-description {
  display: flex;
  flex-direction: column;

  & > h1,
  & > h3 {
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;

    .body--home & {
      margin-bottom: 0;
    }
  }

  & > p {
    line-height: 1.8;
    margin-bottom: 0;
    font-size: 1.2rem;
    word-break: break-word;

    .form-after & {
      line-height: 1.4;
    }

    & + p {
      margin-top: .75rem;
    }

    & a {

    }

    & br {

      & + br {
        display: none;
      }
    }
  }
}

.form-before {
  margin-bottom: 2rem;
}

.form-after {
  margin-top: 2rem;
}

.confirm-award-form {

}

.form-content {

  background: var(--iren-blue-color);
  color: var(--white);

  &__input {
    transition: all .25s ease-in-out;

    &:hover,
    &:focus {
      &:not(input[type=radio]) {
        background: #fff;
        box-shadow: 0 0 0 3px var(--secondary);
      }
    }
  }
}

//.form-content {
//  display: flex;
//  flex-direction: column;
//  max-width: 100%;
//  margin: 0 auto;
//  background: #e58b1e;
//  padding: 2.25rem 2rem;
//  border-radius: 8px;
//
//  &--auth {
//    margin: 2rem auto;
//    border-radius: 12px;
//  }
//
//  &__row {
//    display: flex;
//    flex-direction: column;
//
//    &.horizontal {
//      flex-direction: row;
//      justify-content: space-between;
//    }
//
//    & + & {
//      margin-top: 2rem;
//    }
//
//    &.with-radio-choice {
//      flex-direction: row;
//      align-items: center;
//      margin-bottom: -.5rem;
//
//      & > strong {
//        font-weight: 900;
//        margin-right: 2rem;
//        font-size: 1.5rem;
//      }
//    }
//
//    & > h3 {
//      font-size: 1.25rem;
//      font-weight: 900;
//      line-height: 1;
//      margin-bottom: 2rem;
//    }
//  }
//
//  &__object {
//    display: flex;
//    flex-direction: column;
//    width: 100%;
//
//    .horizontal & {
//      width: initial;
//      flex: 1;
//
//      & + div {
//        margin-left: 2rem;
//      }
//    }
//
//    .with-radio-choice & {
//      flex-direction: row;
//      flex: 1;
//    }
//
//    &__error {
//
//      & ul {
//        display: flex;
//        flex-direction: column;
//        list-style: none;
//        padding: 0;
//        margin: .75rem 0 0 1px;
//        align-items: flex-start;
//
//        .horizontal & {
//          align-items: initial;
//          min-width: 100%;
//        }
//
//        & li {
//          display: inline-flex;
//          background: #f50537;
//          line-height: 1.1;
//          padding: 4px 8px 5px;
//          border-radius: 2px;
//          font-weight: bold;
//          font-size: 12px;
//
//          & + li {
//            margin-top: .35rem;
//          }
//        }
//      }
//    }
//  }
//
//  &__label {
//    display: flex;
//    line-height: 1;
//    margin-bottom: .75rem;
//    font-weight: bold;
//
//    .is-auth-form & {
//      justify-content: center;
//      width: 100%;
//      font-size: 1.5rem;
//      margin-bottom: 1rem;
//    }
//  }
//
//  &__field {
//    width: 100%;
//
//    .with-radio-choice & {
//      width: initial;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//
//      & + div {
//        margin-left: 1rem;
//      }
//
//      & input {
//        width: initial;
//        position: relative;
//        margin-top: 1px;
//
//        &:checked + label {
//          color: #0bbbef;
//          box-shadow: 0 3px 0 0 #0bbbef
//        }
//      }
//
//      & label {
//        font-weight: bold;
//        color: #d0d0d0;
//        margin: 0 0 0 .5rem;
//        cursor: pointer;
//        transition: all .25s ease-in-out;
//      }
//    }
//  }
//
//  &__input {
//    width: 100%;
//    padding: .75rem 1rem .8rem;
//    border: 0 !important;
//    border-radius: 4px !important;
//    background: #f0f0f0;
//    font-weight: 500;
//    line-height: 1;
//    outline: none !important;
//    color: #666 !important;
//    transition: all .25s ease-in-out;
//
//    .is-auth-form & {
//      padding: 1.5rem;
//      font-size: 1.25rem;
//      font-weight: bold;
//      text-align: center;
//    }
//
//    &:hover,
//    &:focus {
//      &:not(input[type=radio]) {
//        background: #fff;
//        box-shadow: 0 0 0 4px #216cad;
//      }
//    }
//  }
//
//  & textarea {
//    height: 180px;
//    line-height: 1.4;
//    font-weight: normal;
//    font-size: 1rem;
//  }
//
//  &__prizes {
//
//    & > p {
//      line-height: 1.8;
//      margin-bottom: 0;
//      font-size: 1.2rem;
//
//      & + p {
//        margin-top: .75rem;
//      }
//    }
//  }
//}

.blobs-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.blob-wrapper {
  position: absolute;
  z-index: 0;
  transition: all .25s ease-in-out;

  & .blob {
    width: 100%;
    height: 100%;
    -webkit-animation: blob 20s linear infinite;
    animation: blob 20s linear infinite;
    border-radius: 43% 57% 55% 45% / 30% 56% 44% 70%;
    box-shadow: -5px 0 4px 0 rgba(0, 0, 0, .20);
    opacity: 0.8;
  }

  &--primary {
    width: 570px;
    height: 667px;
    right: -330px;
    top: -200px;

    & .blob {
      background-image: linear-gradient(102deg, #f50537, #c4042c 100%);
    }
  }

  &--secondary {
    width: 420px;
    height: 447px;
    left: -280px;
    top: 50%;
    transform: translateY(-50%);

    & .blob {
      background-image: linear-gradient(102deg, #f50537, #c4042c 100%);
    }
  }

  &--tertiary {
    width: 420px;
    height: 447px;
    right: -280px;
    bottom: -120px;

    & .blob {
      background-image: linear-gradient(102deg, #f50537, #c4042c 100%);
    }
  }
}

@keyframes blob {

  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(90deg);
  }

  40% {
    transform: rotate(180deg);
  }

  60% {
    transform: rotate(270deg);
  }

  80% {
    transform: rotate(290deg);
  }

  100% {
    transform: rotate(360deg);
  }

}


/**
MODAL
 */
.modal-content {
  border: 0;
}

.modal-header {
  background: var(--color-primary-light);
  color: var(--color-dark);
  line-height: 1;
  align-items: center;
  padding: 1rem 1rem 1.15rem;

  #js-modal-authentication-errors & {
    background: linear-gradient(102deg, var(--primary), var(--secondary) 100%) !important;
  }

  & .close {
    margin: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: .5rem;
    justify-content: center;

  }

  button > span {
    color: var(--color-dark);
  }
}

.modal-title {
  line-height: 1;
  margin-left: .5rem;
  color: var(--white);
}

.block-access-color {
  color: var(--color-black);
}

.modal-body {
  color: var(--color-dark);
  position: relative;

  h3 {
    font-size: 1.5rem;
  }
}

.modal-close-btn-wrapper {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}


/**
MEDIA
 */
@media all and (max-width: 991px) {

  .navbar .container {
    max-width: initial;
    padding: 0 1rem;
  }

  .navbar .navbar-brand {
    max-width: 200px;
  }

  body > .container {
    max-width: 100%;
    padding: 2rem;
    margin-top: 96px;
  }

  .blob-wrapper {
    transform: scale(.85);
  }

}
//
//@media all and (max-width: 600px) {
//
//  html {
//    font-size: 14px;
//  }
//
//  .form-description > h1,
//  .form-description > h3 {
//    font-size: 1.5rem;
//    line-height: 1.3;
//  }
//
//  body > .container {
//    padding: 1rem;
//    margin-top: 80px;
//  }
//
//  .blob-wrapper {
//    transform: scale(.75);
//  }
//
//  .navbar .navbar-brand {
//    max-width: 160px;
//  }
//
//  .jumbotron.jumbotron {
//    padding: 1.5rem 1.25rem;
//  }
//
//  .products {
//    align-items: flex-start;
//
//    &__container {
//      flex-direction: column;
//      width: 100%;
//      align-items: center;
//    }
//  }
//
//  .product {
//    width: 300px;
//    max-width: 100%;
//    margin: 0;
//
//    & + & {
//      margin-top: 2rem;
//      margin-left: 0;
//    }
//
//    &__image {
//      //height: 140px;
//    }
//
//    &__body {
//      padding: .75rem;
//    }
//  }
//
//  .form-content {
//    padding: 1.25rem;
//
//    &__row {
//
//      &.horizontal {
//        flex-direction: column;
//        justify-content: space-between;
//      }
//    }
//
//    &__object {
//
//      .horizontal & {
//        display: flex;
//        flex-direction: column;
//        width: 100%;
//
//        & + div {
//          margin-left: 0;
//          margin-top: 2rem;
//        }
//      }
//    }
//  }
//
//  .form-description {
//    padding: 0 4px;
//  }
//}

//
//@media all and (max-width: 420px) {
//
//  #captchaWrapper {
//
//    & > div,
//    & iframe {
//      width: 100% !important;
//    }
//  }
//
//  .blob-wrapper {
//    transform: scale(.66);
//  }
//
//  .navbar .container {
//    padding: 0;
//  }
//
//  .navbar .navbar-brand {
//    max-width: 124px;
//  }
//
//  .products {
//    flex-direction: column;
//    align-items: center;
//    justify-content: center;
//  }
//
//  .product {
//    width: initial;
//    max-width: 98%;
//
//    & + & {
//      margin: 2rem 0 0 0;
//    }
//
//    &__image {
//      height: inherit;
//    }
//  }
//
//  .form-content--auth {
//    padding: 2rem;
//  }
//
//  .is-auth-form .form-content__label {
//    font-size: 1.25rem;
//    letter-spacing: .5px;
//  }
//
//  .is-auth-form .form-content__input {
//    font-size: 1.1rem;
//    padding: 1.5rem 1rem;
//  }
//}

.container.container-footer {
  padding: 0;
}
.footer{
  margin-bottom: 2rem;
 // background: linear-gradient(90deg, var(--secondary) 0%, var(--primary) 100%);
  background-color: var(--color-primary-light-lighter);
  color: var(--color-primary-dark);
  font-weight: normal;

  a{
    color: var(--color-primary-dark);
    text-decoration: underline;
  }
}
